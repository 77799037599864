import {Component, OnDestroy, OnInit} from '@angular/core';
import {GraphicsMap} from '@/models/GraphicsMap';
import {GALLERY_GRAPHICS_DESCRIPTION, GALLERY_GRAPHICS_TYPES, GALLERY_MIN_FILE_EXT, PUBLIC_GRAPHICS_IMAGES, TITLE_APPEND} from '@/Constants';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Data, Router} from '@angular/router';
import {UtilsService} from '@/services/utils.service';
import {GalleryService} from '@galleries/services/gallery.service';
import {Meta, Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-gallery-graphics-category',
  templateUrl: './gallery-graphics-category.component.html',
  styleUrls: ['../_gallery-graphics.component.scss']
})
export class GalleryGraphicsCategoryComponent implements OnInit, OnDestroy {
  public itemsCategory = '';
  public galleryError: boolean;
  public GALLERY_MIN_FILE_EXT = GALLERY_MIN_FILE_EXT;
  // public GALLERY_FILE_EXT = GALLERY_FILE_EXT;
  public PUBLIC_GRAPHICS_IMAGES = PUBLIC_GRAPHICS_IMAGES;
  public GALLERY_GRAPHICS_TYPES_KEYS = Object.keys(GALLERY_GRAPHICS_TYPES);
  public GALLERY_GRAPHICS_TYPES = GALLERY_GRAPHICS_TYPES;
  public thumbs: GraphicsMap = new GraphicsMap([], [], []);
  private sub: Subscription;
  public paramsSub: Subscription;

  constructor(private _router: Router,
              private _utilsService: UtilsService,
              private _galleryService: GalleryService,
              private _activatedRoute: ActivatedRoute,
              private _title: Title,
              private _meta: Meta,
              private translate: TranslateService) {}

  ngOnInit(): void {
    this.getItems();
  }

  /**
   * Get graphics items for category.
   */
  getItems() {
    this.paramsSub = this._activatedRoute.data.subscribe((params: Data) => {
      if (typeof params.category !== 'undefined') {
        if (this.GALLERY_GRAPHICS_TYPES_KEYS.indexOf(params.category) > -1) {
          this.itemsCategory = params.category;

          this._title.setTitle(`Graphics - ${UtilsService.toTitleCase(this.itemsCategory)}${TITLE_APPEND}`);
          this._meta.updateTag({property: 'og:title', content: `Graphics - ${UtilsService.toTitleCase(this.itemsCategory)}${TITLE_APPEND}`});
          this._meta.updateTag({property: 'og:image', content: 'https://www.sandbay.it/assets/logo.png'});
          this._meta.updateTag({property: 'og:url', content: `https://www.sandbay.it/gallery-graphics/${params.category}/`});
          this._utilsService.createLinkForCanonicalURL(`https://www.sandbay.it/gallery-graphics/${params.category}/`);
          this._meta.updateTag({name: 'description', content: GALLERY_GRAPHICS_DESCRIPTION});
          this._meta.updateTag({property: 'og:description', content: GALLERY_GRAPHICS_DESCRIPTION});
          // this.translate.get(`menu.modelsDescription${UtilsService.toTitleCase(this.itemsCategory)}`) // TODO Description for category.
          //   .subscribe( (description: string) => {
          //     const descriptionText = description.replace('<br/>', '');
          //     this._meta.updateTag({ name: 'description', content: descriptionText });
          //     this._meta.updateTag({property: 'og:description', content: descriptionText });
          //   });
        }
        this.sub = this._galleryService.getGraphics().subscribe({
          next: res => {
            this.thumbs = res;
          },
          error: err => {
            this.galleryError = UtilsService.handleGalleryError(err);
          }
        });
      }});
  }

  /**
   * Show single item when click on thumb.
   */
  showItem(name) {
    void this._router.navigate([{ outlets: { asset: ['graphic', name] } }]);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
