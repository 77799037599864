import {Component, OnDestroy, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {GALLERY_TOOLS_DESCRIPTION, GALLERY_TOOLS_TYPES, TITLE_APPEND} from '@/Constants';
import {GalleryService} from '@galleries/services/gallery.service';
import {Router} from '@angular/router';
import {UtilsService} from '@/services/utils.service';

@Component({
  selector: 'app-gallery-tools',
  templateUrl: './gallery-tools.component.html',
  styleUrls: ['./_gallery-tools.component.scss']
})
export class GalleryToolsComponent implements OnInit, OnDestroy {
  public galleryError: boolean;
  public thumbs: any;
  public GALLERY_TOOLS_TYPES = GALLERY_TOOLS_TYPES;
  public GALLERY_TOOLS_TYPES_KEYS = Object.keys(GALLERY_TOOLS_TYPES);
  private sub: Subscription;

  constructor(private _router: Router,
              private _utilsService: UtilsService,
              private _galleryService: GalleryService,
              private _title: Title,
              private _meta: Meta) {
  }

  ngOnInit() {
    this._title.setTitle(`Tools${TITLE_APPEND}`);
    this._meta.updateTag({name: 'description', content: GALLERY_TOOLS_DESCRIPTION});
    this._meta.updateTag({property: 'og:title', content: `Tools${TITLE_APPEND}`});
    this._meta.updateTag({property: 'og:description', content: GALLERY_TOOLS_DESCRIPTION});
    this._meta.updateTag({property: 'og:image', content: 'https://www.sandbay.it/assets/logo.png'});
    this._meta.updateTag({property: 'og:url', content: `https://www.sandbay.it/gallery-tools/`});
    this._utilsService.createLinkForCanonicalURL(`https://www.sandbay.it/gallery-tools/`);
    this.sub = this._galleryService.getTools().subscribe({
      next: res => {
        this.thumbs = res;
      },
      error: err => {
        this.galleryError = UtilsService.handleGalleryError(err);
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  showItem(name) {
    void this._router.navigate([{ outlets: { asset: ['tool', name] } }]);
  }

  /**
   * trackBy for performance.
   */
  public trackById(index, item) {
    return item.title;
  }
}
