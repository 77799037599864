<div id="container" class="fadeIn def_back gallery-tools container-md">
  <div id="container-border"></div>
  <div id="container-bottom"></div>
  <div *ngFor="let TYPE of GALLERY_TOOLS_TYPES_KEYS" class="tools_sezs">
    <div>
      <h1 class="titles" *ngIf="TYPE === 'tools'">
        <span class="title-icon">
          <i class="fas fa-{{GALLERY_TOOLS_TYPES[TYPE]}}"></i>
        </span>
        <span>{{ TYPE }}</span>
      </h1>
      <h2 class="titles" *ngIf="TYPE !== 'tools'">
        <span class="title-icon">
          <i class="fas fa-{{GALLERY_TOOLS_TYPES[TYPE]}}"></i>
        </span>
        <span>{{TYPE === 'beta' ? 'beta / tests' : TYPE}}</span>
      </h2>
      <div class="row" *ngIf="TYPE === 'tools'">
        <div id="page-description" class="col-12">
          <p [innerHTML]="'menu.toolsDescription' | translate" class="text-center mb-4"></p>
        </div>
      </div>
    </div>

    <div *ngIf="!thumbs && !galleryError" class="lds-css">
      <app-loading></app-loading>
    </div>
    <div *ngIf="!thumbs && galleryError" class="gallery-error">
      <i class="far fa-times-circle"></i>
      {{'errors.UNKNOWN' | translate}}
    </div>
    <div *ngIf="thumbs" class="thumbs">
      <app-tool *ngFor="let elem of thumbs[TYPE]; trackBy: trackById"
                   [tool]="elem" [toolType]="TYPE"></app-tool>
    </div>
  </div>
</div>
