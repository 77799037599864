<div id="container" class="fadeIn def_back gallery-graphics container-lg">
  <div id="container-border"></div>
  <div id="container-bottom"></div>
  <h1 id="graphics-title" class="titles" [innerHTML]="'menu.sezGraphics' | translate"></h1>

  <div class="row">
    <div id="page-description" class="col-12">
      <p [innerHTML]="'menu.graphicsDescription' | translate" class="text-center mb-4"></p>
    </div>
  </div>

  <div *ngFor="let TYPE of GALLERY_GRAPHICS_TYPES_KEYS" class="graphic_sezs {{TYPE}}">
    <div class="graphic_headers">
      <div class="titles-div">
        <span class="hr-left"></span>
        <h2 class="titles">
          <span class="title-icon">
            <i class="fas fa-13x fa-{{GALLERY_GRAPHICS_TYPES[TYPE]}}"></i>
          </span>
          <span>{{('general.lang' | translate) == 'en' ? TYPE : TYPE}}</span>
        </h2>
        <span class="hr-right"></span>
      </div>
      <p class="page-description"></p>
    </div>
    <div *ngIf="thumbs[TYPE].length === 0 && !galleryError" class="lds-css">
      <app-loading></app-loading>
    </div>
    <div *ngIf="thumbs[TYPE].length === 0 && galleryError" class="gallery-error">
      <i class="far fa-times-circle"></i>
      {{'errors.UNKNOWN' | translate}}
    </div>
    <ngu-carousel #myCarousel *ngIf="thumbs" [inputs]="carouselConfig" class="thumbs"
                  [dataSource]="thumbs[TYPE]">
      <ngu-item *nguCarouselDef="let elem;" class="item">
        <a rel="{{elem.type}}_group" (click)="showItem(elem.name)" [routerLink]="[{ outlets: { asset: ['graphic', elem.name] } }]"
           (auxclick)="onWheelClick($event, TYPE, elem)">
          <span class="{{elem.type}}" id="{{elem.name}}">
            <picture *ngIf="TYPE !== 'videos'">
              <source [srcset]="PUBLIC_GRAPHICS_IMAGES + 'webp/' + elem.name + '.min.webp'"  type="image/webp">
              <img draggable="false" [src]="PUBLIC_GRAPHICS_IMAGES + elem.name + GALLERY_MIN_FILE_EXT"
                   [alt]="elem.title" width="180px" height="180px">
            </picture>
            <picture *ngIf="TYPE === 'videos'">
              <source [srcset]="'https://img.youtube.com/vi_webp/' + elem['thumbYoutube'] + '/mqdefault.webp'"  type="image/webp">
              <img draggable="false" [src]="'https://img.youtube.com/vi/' + elem['thumbYoutube'] + '/mqdefault.jpg'"
                   [alt]="elem.title" width="180px" height="101px">
            </picture>
            <!--<img draggable="false" loading="lazy" alt="{{elem.name}}"
                 [src]="PUBLIC_GRAPHICS_IMAGES + elem.name + GALLERY_MIN_FILE_EXT"/>
              <img draggable="false" loading="lazy" alt="{{elem.name}}" width="180px"
                 [src]="'https://img.youtube.com/vi/' + elem['thumbYoutube'] + '/mqdefault.jpg'" />-->
            <i draggable="false" *ngIf="TYPE === 'videos'" class="fa-2x fa-solid fa-circle-play">&nbsp;</i>
          </span>
        </a>
        <p class="graphic-descs" [innerHTML]="('general.lang' | translate) == 'it' ? elem.title : elem['titleEn']"></p>
      </ngu-item>

      <ul *ngIf="isLoadedCarousel" class="nguCarouselPoint" NguCarouselPoint>
        <li *ngFor="let carouselItem of myCarousel.pointNumbers; let j = index" [class.active]="j === myCarousel.activePoint"
            (click)="myCarousel.moveTo(carouselItem)"></li>
      </ul>

      <button NguCarouselPrev class="rs leftRs"><i class="fas fa-2x fa-angles-left"></i><span class="sr-only">Left with {{TYPE}}</span></button>
      <button NguCarouselNext class="rs rightRs"><i class="fas fa-2x fa-angles-right"></i><span class="sr-only">Right with {{TYPE}}</span></button>
    </ngu-carousel>
  </div>
</div>
