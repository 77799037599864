<div id="container" class="fadeIn def_back gallery-websites container-md">
  <div id="container-border"></div>
  <div id="container-bottom"></div>
  <h1 id="websites-title" class="titles">
    <i class="fa-globe fas fa-13x"></i>
    {{'menu.sezWebsites' | translate }}
  </h1>

  <div class="row">
    <div id="page-description" class="col-12">
      <p [innerHTML]="'menu.websitesDescription' | translate"
         class="text-center mb-4"></p>
    </div>
  </div>

  <div *ngIf="!items && !galleryError" class="lds-css">
    <app-loading></app-loading>
  </div>
  <div *ngIf="!items && galleryError" class="gallery-error">
    <i class="far fa-times-circle"></i>
    {{'errors.UNKNOWN' | translate}}
  </div>
  <div class="thumbs row">
    <div *ngFor="let elem of items; trackBy: trackById" class="col-lg-12 col-xl-6 mb-4">
      <div class="web-thumb {{elem.type | lowercase }}"
          [ngClass]="{'older': elem.old, 'more': more}">
        <a class="web-link" target="_blank" rel="noopener noreferrer nofollow"
           title="{{elem.title}}" [href]="elem.path">
          <picture>
            <source [srcset]="OBYTE_HOST_UTIL + 'public/websites/webp/' + elem.name + '2.mobile.webp'"
                    media="(max-width: 669px)" type="image/webp">
            <source [srcset]="OBYTE_HOST_UTIL + 'public/websites/webp/' + elem.name + '2.min.webp'" type="image/webp">
            <img draggable="false" [src]="OBYTE_HOST_UTIL + 'public/websites/' + elem.name + '2.min.jpg'"
                 [alt]="elem.title" width="234px" height="180px">
          </picture>
          <!--<img draggable="false" loading="lazy" alt="{{elem.title}}" width="234px" height="180px"
               [src]="OBYTE_HOST_UTIL + 'public/websites/' + elem.name + '2.min.jpg'" />-->
          <div class="year">
            <span>{{elem.year}}</span>
            <span *ngIf="elem.state" class="beta-tag {{elem.state.toLowerCase()}}">{{elem.state}}</span>
            <i class="fas fa-external-link-alt"></i>
          </div>
        </a>
        <div class="desc def_back">
          <h2 class="title pt-lg-1 pt-xl-3">{{elem.title}}</h2>
          <span class="description" [innerHTML]="('general.lang' | translate) == 'it' ? elem.description : elem.descriptionEn"></span>
          <span class="web-tech-label mb-2 mx-auto" [innerHTML]="('websites.technology' | translate) + ':'"></span>
          <!--<i class="fab fa-3x fa-{{elem.type | lowercase }} fa-type"
             tooltipClass="{{elem.type | lowercase }}" triggers="hover click"
             placement="bottom" [autoClose]="'outside'" ngbTooltip="{{elem.type}}"></i>-->
          <p class="web-tech-container text-center">
            <i class="fab fa-3x fa-{{elem.type | lowercase }} fa-type"></i>
            <span class="web-tech">{{elem.type}}</span>
          </p>
        </div>
      </div>
      <!--<li *ngIf="items">
        <button type="button" class="input-submit" *ngIf="!more" (click)="showOlder()">
          Show older
        </button>
      </li>-->
    </div>
  </div>
</div>
