<div id="container" class="fadeIn def_back gallery-models container-md">
  <div id="container-border"></div>
  <div id="container-bottom"></div>
  <div class="row">
    <div class="col-2"></div>
    <h1 class="titles {{itemsCategory}} col-8">
      <i *ngIf="itemsCategory else modelsTitle" class="fas fa-13x fa-{{GALLERY_MODELS_TYPES[itemsCategory]}}">&nbsp;</i>
      <ng-template #modelsTitle>
        <i class="fas fa-13x fa-cubes">&nbsp;</i>
        <!--<svg>
          <use xlink:href="/assets/fontawesome-6.0.0-web/sprites/solid.svg#cubes"></use>
        </svg>-->
      </ng-template>
      <span *ngIf="itemsCategory">3D&nbsp;<br/></span>
      <span>{{('menu.sez3dModels' + ((itemsCategory | titlecase) || 'Double')) | translate}}</span>
    </h1>
    <div class="col-2">
      <app-filters class="d-none d-md-block" *ngIf="items" [configs]="configs" [isFiltered]="configs.isFiltered"
                   (filtering)="getItems(1, $event)" [filterId]="'top'"></app-filters>
    </div>
  </div>
  <div class="row">
    <div id="page-description" class="col-12 {{itemsCategory}}">
      <p [innerHTML]="'menu.modelsDescription' + ((itemsCategory | titlecase) || '') | translate"
         class="text-center mb-4"></p>
    </div>
  </div>

  <app-breadcrumbs *ngIf="itemsCategory" [nodes]="[{label: '3D Models', url: 'gallery-models'},
    {label: '3D ' + (('menu.sez3dModels' + (itemsCategory | titlecase)) | translate)}]"></app-breadcrumbs>

  <div *ngIf="!items && loading" class="lds-css">
    <app-loading></app-loading>
  </div>
  <div *ngIf="items?.length < 1 && !loading && !galleryError" class="row">
    <div class="col-2"></div>
    <p class="col-8 gallery-empty">
      <i class="fas fa-sliders-h">&nbsp;</i>
      {{'models.galleryEmpty' | translate}}
    </p>
    <div class="col-2"></div>
  </div>
  <div *ngIf="!items && galleryError" class="gallery-error">
    <i class="far fa-times-circle"></i>
    {{'errors.UNKNOWN' | translate}}
  </div>
  <ul class="thumbs">
    <li *ngFor="let elem of items | paginate: { itemsPerPage: configs.itemsPerPage, currentPage: p,
      totalItems: (itemsPages * configs.itemsPerPage) }; trackBy: trackById" class="thumb {{elem.type}}"
        [class.category]="!itemsCategory">
      <app-loading [classes]="'mini'"></app-loading>
      <a (click)="showItem(elem.name)" [routerLink]="'/gallery-models/asset-3d/' + elem.name + '/.'"
         (auxclick)="onWheelClick($event, elem.name)">
<!--      <a (click)="showItem(elem.name)" [routerLink]="[{ outlets: { asset: ['3d', elem.name, 1] } }]"-->
        <picture>
          <source [srcset]="PUBLIC_MODELS_IMAGES_3D + 'webp/' + elem.name + '.min' + GALLERY_FILE_EXT_NEW"  type="image/webp">
          <img draggable="false" [src]="PUBLIC_MODELS_IMAGES_3D + elem.name + '.min.jpg'"
               [alt]="elem.titleEn" width="100%" height="100%" loading="lazy">
        </picture>
        <!--<img draggable="false" loading="lazy" [src]="PUBLIC_MODELS_IMAGES_3D + elem.name + '.min.jpg'"
             width="100%" height="100%" alt="{{elem.name}}"/>-->
        <span class="thumb_title" [innerHTML]="('general.lang' | translate) == 'it' ? elem.title : elem.titleEn"></span>
      </a>
      <i *ngIf="!itemsCategory" class="fas fa-{{GALLERY_MODELS_TYPES[elem.type]}}"></i>
    </li>
  </ul>
  <div class="row">
    <div class="col-2">
      <!--<div id="count">{{count}}</div>-->
    </div>

    <pagination-template class="pagination col-8" #pages="paginationApi" *ngIf="items" maxSize="6">
      <ul class="ngx-pagination" aria-label="Pagination" *ngIf="pages.pages.length > 1">
        <li *ngFor="let page of pages.pages" [class.current]="p === page.value">
<!--          <button type="button" (click)="goToPage(page.value)" [id]="'go-page-' + page.value">-->
<!--            <span class="show-for-sr">You're on page </span>-->
<!--            <span>{{ page.label }}</span>-->
<!--          </button>-->
          <a [id]="'go-page-' + page.value" [routerLink]="goToPage()"
             [queryParams]="{page: page.value}" rel="noopener noreferrer">
            <span class="show-for-sr">You're on page </span>
            <span>{{ page.label }}</span>
          </a>
        </li>
      </ul>
    </pagination-template>

    <div class="col-2">
      <app-filters *ngIf="items" [configs]="configs" [isFiltered]="configs.isFiltered" [count]="count"
                   (filtering)="getItems(1, $event)" [directionTop]="true" [filterId]="'bottom'"></app-filters>
    </div>
  </div>
</div>
