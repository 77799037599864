<div class="home-container section-{{i}}"
     (click)="rotated = !rotated" [class.rotated]="rotated"><!--  [routerLink]="section.link" -->
  <img draggable="false" alt="Decorative exagon {{i}}" [src]="'/assets/svgs/polygon_green.svg'">
  <img draggable="false" alt="Decorative exagon on hover {{i}}" loading="lazy" class="hover" [src]="'/assets/svgs/polygon_blue.svg'">
  <div class="home-exagon">
    <i class="fa-3x {{section.icon}} home-icon"></i>
    <span class="title">
      <span [innerHTML]="'menu.' + section.title | translate"></span>
      <span class="hrRight"></span>
    </span>
    <p [innerHTML]="'index.' + section.desc | translate"></p>
    <a class="cta" href="javascript:void(0)" [routerLink]="section.link">
      <span><i class="fa-15x fa-solid fa-chevron-down"></i><span class="sr-only">Go to {{ 'menu.' + section.title | translate }}</span></span>
    </a>
  </div>
</div>
